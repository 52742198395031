<!-- 授信详情 -->
<template>
  <el-main>
    <page-title>
      <template slot="btn-inner">
        <debounce-button v-loading="loading" type="primary" @click="handleSave">
          提交
        </debounce-button>
        <el-button @click="$router.back()">
          <i class="iconfont icon-fanhui" />返回
        </el-button>
      </template>
    </page-title>
    <div class="partition-area">
      <el-form ref="templateDetail" :rules="rules" :model="templateDetail" label-width="170px">
        <div class="detail-area">
          <div class="area-title">
            <p class="title">
              服务费模板信息
            </p>
          </div>
          <div class="detail-inner">
            <!-- 核心企业 -->
            <el-row :gutter="80">
              <el-col :span="12">
                <el-form-item prop="coreId" label="核心企业：">
                  <el-select v-model="templateDetail.coreId" placeholder="请选择核心企业" class="input_w80" filterable @change="coreChange">
                    <el-option v-for="item in coreList" :key="item.coreId" :label="`${item.coreName}（${item.coreCode}）`" :value="item.coreId" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="占比(%)：" prop="coreProportion">
                  <el-input v-model="templateDetail.coreProportion" type="number" placeholder="请输入核心企业服务费占比" class="input_w80" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="80">
              <el-col :span="12">
                <el-form-item prop="coreAccount" label="账户号码：">
                  <el-select v-model="templateDetail.coreAccount" placeholder="请选择账户号码" class="input_w80" filterable @change="(val)=>accountChange(val, 'core')">
                    <el-option v-for="item in coreAccountList" :key="item.eaccountNumber" :label="`${item.eaccountName}（${item.eaccountNumber}）`" :value="item.eaccountNumber" />
                  </el-select>
                  <span style="margin-left: 10px;">
                    <el-radio v-model="defaultAccount" label="1">{{ '' }}</el-radio>
                  </span>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 运营方 -->
            <el-row :gutter="80">
              <el-col :span="12">
                <el-form-item label="运营方：" prop="channelName">
                  {{ templateDetail.channelName }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="占比(%)：" prop="channelProportion">
                  <el-input v-model="templateDetail.channelProportion" type="number" placeholder="请输入运营方服务费占比" class="input_w80" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="templateDetail.channelName" :gutter="80">
              <el-col :span="12">
                <el-form-item prop="channelAccount" label="账户号码：">
                  <el-select v-model="templateDetail.channelAccount" placeholder="请选择账户号码" class="input_w80" filterable @change="(val)=>accountChange(val, 'channel')">
                    <el-option v-for="item in channelAccountList" :key="item.eaccountNumber" :label="`${item.eaccountName}（${item.eaccountNumber}）`" :value="item.eaccountNumber" />
                  </el-select>
                  <span style="margin-left: 10px;">
                    <el-radio v-model="defaultAccount" label="2">{{ '' }}</el-radio>
                  </span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="80">
              <el-col :span="12">
                <el-form-item prop="capId" label="资金方：">
                  <el-select v-model="templateDetail.capId" placeholder="请选择资金方" class="input_w80" filterable @change="capChange">
                    <el-option v-for="item in capList" :key="item.capId" :label="`${item.capName}（${item.capCode}）`" :value="item.capId" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="占比(%)：" prop="capProportion">
                  <el-input v-model="templateDetail.capProportion" type="number" placeholder="请输入资金方服务费占比" class="input_w80" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="templateDetail.capId" :gutter="80">
              <el-col :span="12">
                <el-form-item prop="capAccount" label="账户号码：">
                  <el-select v-model="templateDetail.capAccount" placeholder="请选择账户号码" class="input_w80" filterable @change="(val)=>accountChange(val, 'cap')">
                    <el-option v-for="item in capAccountList" :key="item.eaccountNumber" :label="`${item.eaccountName}（${item.eaccountNumber}）`" :value="item.eaccountNumber" />
                  </el-select>
                  <span style="margin-left: 10px;">
                    <el-radio v-model="defaultAccount" label="3">{{ '' }}</el-radio>
                  </span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="80">
              <el-col :span="12">
                <el-form-item label="平台技术占比(%)：" prop="tenantTechProportion">
                  <el-input v-model="templateDetail.tenantTechProportion" placeholder="请输入平台技术服务费占比" class="input_w80" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="平台运营占比(%)：" prop="tenantSystemProportion">
                  <el-input v-model="templateDetail.tenantSystemProportion" type="number" placeholder="请输入平台运营服务费占比" class="input_w80" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="80">
              <el-col :span="12">
                <el-form-item prop="tenantTechAccount" label="账户号码：">
                  <el-select v-model="templateDetail.tenantTechAccount" placeholder="请选择账户号码" class="input_w80" filterable @change="(val)=>accountChange(val, 'tech')">
                    <el-option v-for="item in tenantAccountList" :key="item.eaccountNumber" :label="`${item.eaccountName}（${item.eaccountNumber}）`" :value="item.eaccountNumber" />
                  </el-select>
                  <span style="margin-left: 10px;">
                    <el-radio v-model="defaultAccount" label="4">{{ '' }}</el-radio>
                  </span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="tenantSystemAccount" label="账户号码：">
                  <el-select v-model="templateDetail.tenantSystemAccount" placeholder="请选择账户号码" class="input_w80" filterable @change="(val)=>accountChange(val, 'system')">
                    <el-option v-for="item in tenantAccountList" :key="item.eaccountNumber" :label="`${item.eaccountName}（${item.eaccountNumber}）`" :value="item.eaccountNumber" />
                  </el-select>
                  <span style="margin-left: 10px;">
                    <el-radio v-model="defaultAccount" label="5">{{ '' }}</el-radio>
                  </span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="80">
              <el-col :span="24">
                <el-col :span="24" class="tc btn-unified">
                  <span style="color: red; font-size: 14px;">注：请选择一个账户为初始收费账户</span>
                </el-col>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-form>
    </div>
  </el-main>
</template>
<script>
import axios from 'axios'
export default {
  components: {
  },
  data () {
    const validateProportion = (rule, value, callback) => {
      if(Number(value) < 0) {
        callback(new Error('占比不能小于0'))
      } else if (Number(value) > 100) {
        callback(new Error('占比不能大于100'))
      } else {
        callback()
      }
    }

   return {
      isShow: false,
      templateDetail: {
	      templateCode: '',
	  	  tenantTechProportion: '',
	      tenantSystemProportion: '',
	      coreId: '',
	      coreCode: '',
	      coreName: '',
	      coreProportion: '',
	      capId: '',
	      capCode: '',
	      capName: '',
	      capProportion: '',
	      channelId: '',
	      channelCode: '',
	      channelName: '',
	      channelProportion: '',
        coreAccount: '',
        coreAccountName: '',
        channelAccount: '',
        channelAccountName: '',
        capAccount: '',
        capAccountName: '',
        tenantTechAccount:'',
        tenantTechAccountName:'',
        tenantSystemAccount:'',
        tenantSystemAccountName:'',
	      id: null
      },
      defaultAccount: '',
      loading: false ,
      rules: {
      	coreId: [
          { required: true, message: '请选择核心企业', trigger: 'change' }
        ],
        capId: [
          { required: true, message: '请选择资金方', trigger: 'change' }
        ],
        coreProportion: [
          { required: true, message: '请选输入核心企业服务费占比0.00~100.00', trigger: 'blur' },
          { validator: validateProportion, trigger: 'blur' }
        ],
        capProportion: [
          { required: true, message: '请选输入资金方服务费占比0.00~100.00', trigger: 'blur' },
          { validator: validateProportion, trigger: 'blur' }
        ],
        channelProportion: [
          { required: true, message: '请选输入资金方服务费占比0.00~100.00', trigger: 'blur' },
          { validator: validateProportion, trigger: 'blur' }
        ],
        tenantTechProportion: [
          { required: true, message: '请输入平台技术占比0.00~100.00', trigger: 'blur' },
          { validator: validateProportion, trigger: 'blur' }
        ],
        tenantSystemProportion: [
          { required: true, message: '平台运营占比0.00~100.00', trigger: 'blur' },
          { validator: validateProportion, trigger: 'blur' }
        ],
      },
      capList: [],
      capAccountList: [],
      coreList: [],
      coreAccountList: [],
      channelAccountList: [],
      tenantAccountList: [],
      create_op: false,
      edit_op: false,
      detail_op: false,
  }
  },
  watch: {},
  created () {
    this.id = this.$route.query.id
    this.getCapList()
    this.getCoreList()
    this.getTenantAccountInfo()
    if (this.id) {
      this.getDataById()
    }

  },
  methods: {
    getDataById(){
    	this.api.serviceCharge.getTemplateDetail({id:this.id}).then(res => {
        const data = res.data.data || {}
        this.templateDetail = data
        this.defaultAccount = String(data.defaultAccount)
        if (this.templateDetail.coreCode) {
          this.getAccountInfo(this.templateDetail.coreCode, 'core')
        }
        if (this.templateDetail.capCode) {
          this.getAccountInfo(this.templateDetail.capCode, 'cap')
        }
        if (this.templateDetail.channelCode) {
          this.getAccountInfo(this.templateDetail.channelCode, 'channel')
        }
      })
    },
    getWidth () {
      this.conWidth.width = window.innerHeight - 120 + 'px'
    },
    created () {
      window.addEventListener('resize', this.getWidth)
      this.getWidth()
    },
    handleSave () {
      this.$refs.templateDetail.validate((valid) => {
       if (valid) {
       const  totalProportion=  Number(this.templateDetail.tenantTechProportion)+Number(this.templateDetail.tenantSystemProportion)+Number(this.templateDetail.coreProportion)+Number(this.templateDetail.capProportion)+Number(this.templateDetail.channelProportion)
         if(totalProportion != 100){
           this.$message.warning('所有占比综合必须为100')
           return false
         }
         if (!this.defaultAccount) {
           this.$message.warning('请选择一个账户为初始收费账户')
           return false
         }
         this.templateDetail.defaultAccount = Number(this.defaultAccount)
         console.log(this.templateDetail)
         this.loading = true
         if(this.id){
            this.api.serviceCharge.updateTemplate(this.templateDetail).then(result => {
              if (result.data.success === true) {
                this.$message.success('修改服务费模板成功')
                this.loading = false
                this.$router.back()
              } else {
                this.loading = false
                this.$message.error(result.data.message)
              }
            }).catch(e => {
              this.loading = false
            })
          }else{
            this.api.serviceCharge.addTemplate(this.templateDetail).then(result => {
              if (result.data.success === true) {
                this.$message.success('添加服务费模板成功')
                this.loading = false
                this.$router.back()
              } else {
                this.loading = false
                this.$message.error(result.data.message)
              }
            }).catch(e => {
              this.loading = false
            })
           }
           }
        })

    },
    getCapList () {
      this.api.company.capital.getCapList().then(res => {
        this.capList = res.data.data || []
      })
    },
    getCoreList(){
      this.api.company.core.getCoreList().then(res => {
        this.coreList = res.data.data || []
      })
    },
    coreChange(val){
      this.templateDetail.channelName = ''
      this.templateDetail.channelCode = ''
      this.templateDetail.channelId = ''
      this.templateDetail.coreAccount = ''
      this.templateDetail.coreAccountName = ''
      this.templateDetail.channelAccount = ''
      this.templateDetail.channelAccountName = ''
      this.coreAccountList = []
      this.channelAccountList = []
    	this.coreList.forEach(item => {
        if (item.coreId === val) {
          this.templateDetail.coreName = item.coreName
          this.templateDetail.coreCode = item.coreCode
          this.getAccountInfo(item.coreCode, 'core')
          //加载详情
          this.api.serviceCharge.getChannelByCore({ coreId: item.coreId }).then(result => {
	          const channelCoreRel = result.data.data
	          this.templateDetail.channelName = channelCoreRel.channelName
            this.templateDetail.channelCode = channelCoreRel.channelCode
            this.templateDetail.channelId = channelCoreRel.id
            this.getAccountInfo(channelCoreRel.channelCode, 'channel')
	      }).finally(() => {

	      })
        }
      })
    },
    capChange(val){
      this.templateDetail.capAccount = ''
      this.templateDetail.capAccountName = ''
      this.capAccountList = []
      this.capList.forEach(item => {
        if (item.capId === val) {
          this.templateDetail.capName = item.capName
          this.templateDetail.capCode = item.capCode
          this.getAccountInfo(item.capCode, 'cap')
        }
      })
    },
    getTenantAccountInfo () {
      this.api.serviceCharge.getAccountInfoByCompanyCode().then(result => {
        this.tenantAccountList = result.data.data || []
      })
    },
    getAccountInfo (code, type) {
      this.api.serviceCharge.getAccountInfoByCompanyCode({companyCode: code}).then(result => {
        if (type) {
          if (type === 'core') {
            this.coreAccountList = result.data.data || []
          } else if (type === 'channel') {
            this.channelAccountList = result.data.data || []
          } else if (type === 'cap') {
            this.capAccountList = result.data.data || []
          }
        }
      })
    },
    accountChange (val, type) {
      if (type === 'core') {
        const data = this._.find(this.coreAccountList, { eaccountNumber: val })
        this.templateDetail.coreAccountName = data.eaccountName
      } else if (type === 'channel') {
        const data = this._.find(this.channelAccountList, { eaccountNumber: val })
        this.templateDetail.channelAccountName = data.eaccountName
      } else if (type === 'cap') {
        const data = this._.find(this.capAccountList, { eaccountNumber: val })
        this.templateDetail.capAccountName = data.eaccountName
      } else if (type === 'tech') {
        if (val === this.templateDetail.tenantSystemAccount) {
          this.templateDetail.tenantTechAccount = ''
          this.templateDetail.tenantTechAccountName = ''
          this.$message.error('平台技术与平台运营账户号码不能一致')
          return false
        }
        const data = this._.find(this.tenantAccountList, { eaccountNumber: val })
        this.templateDetail.tenantTechAccountName = data.eaccountName
      } else if (type === 'system') {
        if (val === this.templateDetail.tenantTechAccount) {
          this.templateDetail.tenantSystemAccount = ''
          this.templateDetail.tenantSystemAccountName = ''
          this.$message.error('平台运营与平台技术账户号码不能一致')
          return false
        }
        const data = this._.find(this.tenantAccountList, { eaccountNumber: val })
        this.templateDetail.tenantSystemAccountName = data.eaccountName
      } else {
        return false
      }
    }
  }
}
</script>
<style scoped>
  .input_w80{
    width:80%;
  }
</style>
